import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Accordion, Button, Form } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import MainConfig from './MainConfig';
import LoadingConfig from './LoadingConfig';
import Modeling from './Modeling';
import ModelExplainability from './ModelExplainability';
import Deepxplore from './Deepxplore';

function FormText() {
  //this.mainConfig = React.createRef()
  const [active, setActive] = useState('0');
  return (
    <Container className="main">
      <Row>
        <Col md={{ span: 12 }}>
          <Accordion flush defaultActiveKey="0" onSelect={e => this.setState({active: e})}>
            {active==='0' ? <LoadingConfig isActive={true} /> : <LoadingConfig />}
            {active === '4' ? <MainConfig isActive={true} /> : <MainConfig />}
            {active==='1' ? <Modeling isActive={true} /> : <Modeling />}
            {active==='2' ? <ModelExplainability isActive={true} /> : <ModelExplainability />}
            {active==='3' ? <Deepxplore isActive={true} /> : <Deepxplore />}
          </Accordion>
        </Col>
      </Row>
    </Container>
  )
}
export default FormText;

/*




*/
/*

*/
/*class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: '6'
    }

    /*this.loadingConfig = React.createRef()
    this.modeling = React.createRef()
    this.modelExplainability = React.createRef()
    this.deepxplore = React.createRef()*/
  /*}

  componentDidMount() {

  }

  /*handleClick = () => {
    this.mainConfig.current.handleClick()
    this.loadingConfig.current.handleClick()
    this.modeling.current.handleClick()
    this.modelExplainability.current.handleClick()
    this.deepxplore.current.handleClick()
  }

  handlePreset1 = () => {
    this.mainConfig.current.handlePreset1()
  }

  handlePreset2 = () => {
    this.mainConfig.current.handlePreset2()
  }*/

  /*
  <Form.Group controlId="formGridState">
    <Form.Row>
    </Form.Row>
  </Form.Group>


  */
/*


*/
/*
<Form.Group controlId="formGridState">
  <Form.Row>
    <Col md={8} className="selectOption">

    </Col>

  </Form.Row>
</Form.Group>
<Col md={{ span: 4 }} style={{marginTop: '-50px'}}>
  <Button variant="primary" block size="lg" className="myButton" onClick={this.handleClick}>
    Launch <FontAwesomeIcon icon={faArrowCircleRight} />
  </Button>
</Col>

<Form.Group as={Col} controlId="formGridState">
  <Form.Row>
    <Col md={8} className="selectOption">

    </Col>
    <Col md={{ span: 4 }} style={{marginTop: '-50px'}}>
      <Button variant="primary" block size="lg" className="myButton" onClick={this.handleClick}>
        Launch <FontAwesomeIcon icon={faArrowCircleRight} />
      </Button>
    </Col>
  </Form.Row>
  <br />
  <Form.Row>
    <Col md={2}>

    </Col>
    <Col md={2}>
      <Button variant="primary" size="sm" className="myButton" onClick={this.handlePreset1}>
        Apply Preset 1
      </Button>
    </Col>
    <Col md={2}>
      <Button variant="primary" size="sm" className="myButton" onClick={this.handlePreset2}>
        Apply Preset 2
      </Button>
    </Col>
  </Form.Row>
</Form.Group>

<Accordion defaultActiveKey="6" onSelect={e => this.setState({active: e})}>
  {this.state.active === '6' ? <MainConfig ref={this.mainConfig} isActive={true} /> : <MainConfig ref={this.mainConfig} />}
  {this.state.active==='0' ? <LoadingConfig ref={this.loadingConfig} isActive={true} /> : <LoadingConfig ref={this.loadingConfig} />}
  {this.state.active==='1' ? <Modeling ref={this.modeling} isActive={true} /> : <Modeling ref={this.modeling} />}
  {this.state.active==='2' ? <ModelExplainability ref={this.modelExplainability} isActive={true} /> : <ModelExplainability ref={this.modelExplainability} />}
  {this.state.active==='5' ? <Deepxplore ref={this.deepxplore} isActive={true} /> : <Deepxplore ref={this.deepxplore} />}
</Accordion>
*/
