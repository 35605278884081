import React from "react";
import swal from "sweetalert";
import "react-circular-progressbar/dist/styles.css";
import { auth } from "../../services";

import Auth from "./Auth";
import gif from "../../images/1d.gif";
import FormEle from "./FormEle";
import {
  VALIDATOR_TESTAING_EMAIL,
  VALIDATOR_PASSWORD,
  VALIDATOR_CONFIRM_PASSWORD,
} from "./validator";
import { useForm } from "../../hooks/Auth/useForm";
import "./Auth.scss";

const SignUp = () => {
  const currentUser = auth.getCurrentUser();
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = React.useState("Passwords do not match.");

  if (currentUser) {
    auth.logout();
  }

  const [formState, inputHandler] = useForm(
    {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
      confirmPassword: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  React.useEffect(() => {
    if (formState.inputs.confirmPassword.value !== formState.inputs.password.value) {
      setConfirmPasswordErrorText("Passwords do not match.");
    } else {
      setConfirmPasswordErrorText("");
      formState.inputs.confirmPassword.isValid = true;
    }
  }, [formState.inputs.confirmPassword.value, formState.inputs.password.value]);

  const submitHandler = (event) => {
    event.preventDefault();
    try {
      if(formState.inputs.email.isValid === false || formState.inputs.password.isValid === false || formState.inputs.confirmPassword.isValid === false){
        swal({
          title: "Error",
          text: "Please fill all the fields correctly.",
          icon: "error",
          button: "Ok",
        });
        return;
      }
      if (formState.inputs.confirmPassword.value !== formState.inputs.password.value) {
        swal({
          title: "Error",
          text: "Passwords do not match.",
          icon: "error",
          button: "Ok",
        });
        return;
      }
    } catch (error) {
      swal({
        title: "Error",
        text: error, //error.response.data.message,
        icon: "error",
        button: "Ok",
      });
    }
    auth
      .signup(formState.inputs.email.value, formState.inputs.password.value)
      .then((response) => {
        if (response === "Account already exists for this username.") {
          swal({
            title: "Error",
            text: response, //response.data.message,
            icon: "error",
            button: "Ok",
          });
        } else {
          auth.logout();
          swal({
            title: "Success",
            text: "User created successfully",
            icon: "success",
            button: "Ok",
          }).then(() => {
            window.location.href = "/auth/login";
          });
        }
      })
      .catch((error) => {
        swal({
          title: "Error",
          text: error, //error.response.data.message,
          icon: "error",
          button: "Ok",
        });
      });
  };

  return (
    <Auth
      title="Sign Up"
      switchText="or Already have an account?"
      switchLink="/auth/login"
      img={gif}
    >
      <form className="auth__body__form" onSubmit={submitHandler}>
        <FormEle
          id="email"
          element="input"
          type="email"
          label="E-Mail"
          validators={[VALIDATOR_TESTAING_EMAIL()]}
          errorText="Please enter a valid company email address."
          onInput={inputHandler}
        />
        <FormEle
          id="password"
          element="input"
          type="password"
          label="Password"
          validators={[VALIDATOR_PASSWORD()]}
          errorText="Password should be minimum 8 characters and should contain at least one Uppercase letter and one number. No special characters allowed."
          onInput={inputHandler}
        />
        <FormEle
          id="confirmPassword"
          element="input"
          type="password"
          label="Confirm Password"
          validators={[
            VALIDATOR_CONFIRM_PASSWORD(formState.inputs.password.value),
          ]}
          errorText={confirmPasswordErrorText}
          onInput={inputHandler}          
        />
        <div className="auth__submit__container">
          <FormEle
            id="terms"
            element="checkbox"
            type="checkbox"
            label="Remember me"
            onInput={() => {}}
          />

          <button
            className="submit-button"
            type="submit"
          >
            Sign up
          </button>
        </div>
      </form>
    </Auth>
  );
};

export default SignUp;
